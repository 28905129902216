@import "vars";

.work-card {
  &__header{
    width: 100%;
    border-radius: 10px 10px 0 0;
    padding: 10px 0;
    background-color: rgba(173, 173, 173, 0.5);
    img{
      margin-left: 20px;
    }
  }
  &__body{
    padding: 0 20px;
  }
  &__container {
    width: 50%;
    margin: auto;
    border-radius: 10px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
    background: #fff;
  }
  &__information, &__info{
    color: $bannerTextColor;
    font-size: 20px;
  }
  &__link{
    text-decoration: none;
    &:hover{

    }
  }
}

.job-card {
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
  @include media($tablet){
    flex-direction: column;
  }
}

.company-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-right: 20px;
}

.job-details {
  flex: 1;
}

.years-worked {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: $blueColor;
}

.tech-stack {
  font-size: 20px;
  margin-bottom: 10px;
}

.description {
  color: $bannerTextColor;
  font-size: 20px;
}
