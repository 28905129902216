@import 'vars';
@import 'mixins';

.experience-banner{
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  flex-wrap: nowrap;
  margin: auto;
  @include media($tablet){
    flex-direction: column;
  }
}

.experience-card{
  width: 30%;
  @include media($tablet){
    width: 60%;
  }
  @include media($mobile){
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  margin: 5px 15px;
  @include media($tablet){
    border-radius: 10px;
    padding: 5px;
    margin: 5px auto;
  }
  &__name{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: $blueColor;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
  }
  &__tech{
    width: 35%;
    @include media($tablet){
      width: 60%;
    }
    @include media($mobile){
      width: 100%;
    }
  }
}


.experience-block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  &__title {
    color: $blueColor;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    text-transform: capitalize;
    font-size: .75rem;

    &-dot {
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      text-align: center;
    }
  }

  &__name {
    font-size: 1rem;
    font-weight: bold;
    text-align: start;
    margin-bottom: 1rem;
  }

  &__description {
    color: $experienceDescriptionColor;
  }

}
.experience-tech{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @include media($mobile){
    justify-content: center;
  }
  flex-wrap: wrap;
  &__skill{
    background-color: white;
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2px;
    padding: 10px 5px 0 5px;
    text-transform: capitalize;
    font-weight: bolder;
  }
}

.experience__education{
  color: $blueColor;
  &-line{
    background-color: $blueColor;
  }
}

.experience__work{
  color: #FF5F49;
  &-line{
    background-color: #FF5F49;
  }
}

.experience__tools{
  color: #2DB24A;
  &-line{
    background-color: #2DB24A;
  }
}




