@import "vars";
@import "mixins";
@import "switcher";

.Header{
  @extend %flex-between;
  height: 120px;
  width: 1200px;
  margin: auto;
  @include media($projector){
    width: 900px;
  }
  @include media($desktop){
    width: 90%;
    margin: auto;
  }

}
.nav{
  &__logo{
    letter-spacing: 3px;
    font-size: 24px;
    font-weight: bold;
  }
  &__container{
    @extend %flex-between;
    width: 50%;
    height: 60px;
    @include media($tablet){
      width: 70%;
    }
    @include media($mobile){
      display: none;
    }
  }
  &__item{
    font-size: 18px;
    color: $navItemColor;
    text-decoration: none;
    text-transform: capitalize;
    background-color: transparent;
    border: none;
    cursor: pointer;
    &:hover{
      color: $navItemActive;
    }
    &-active{
      font-weight: 600;
      color: $navItemActive;
    }
  }
  &__button-container{
    background: linear-gradient(90deg, rgba(146,73,242,1) 0%, rgba(255,95,73,1) 100%);
    padding: 1px;
    border-radius: 5px;
  }
  &__button{
    border-radius: 5px;
    background: white;
    border: none;
    &:hover{
      background-color: rgba(255, 255, 255, 0.1);
    }
    &-expand{
      display: none;
      height: 36px;
      @include media($mobile){
        display: block;
      }
      &>img{
        height: 36px;
      }
    }
  }
}