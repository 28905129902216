.project-cards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 5px;
  overflow: hidden;

}