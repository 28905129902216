$linkColor: maroon;
$linkColorHover: lightgreen;

$navItemColor: #7B848B;
$navItemActive: #121314;

$blueColor: #1777E5;
$bannerTextColor: #485057;
$experienceDescriptionColor: #5E6971;
$color-primary-white: rgb(240, 240, 240);

$projector: 1440px;
$desktop: 1024px;
$tablet: 768px;
$mobile: 576px;

%flex-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

