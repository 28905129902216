@import "vars";
@import "mixins";


.about-banner{
  border-radius: 5px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.16);
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 300px;
  padding: 0 30px;
  @include media($tablet){
    flex-direction: column;
    height: auto;
  }
  &_avatar{
    width: 200px;
    margin-right: 20px;
    @include media($tablet){
      width: 150px;
      margin: 0;
    }
    border-radius: 50%;
    background-color: #fbfbfb;
    overflow: hidden;
    &>img{
      margin-top: 5px;
      width: 100%;
    }
  }

  &_content{
    height: 60%;
    width: 50%;
    font-size: 18px;
    font-weight: 200;
    @include media($tablet){
      text-align: center;
      font-size: 14px;
      font-weight: 300;
      color: black;
      width: 90%;
    }
  }
  &_title{
    text-transform: capitalize;
    font-size: 30px;
    letter-spacing: 2px;
    @include media($tablet){
      text-align: center;
    }
  }

  &_buttons{
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media($tablet){
      width: 80%;
      margin-top: 20px;
    }
    &__btn{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      font-size: 15px;
      margin: 5px auto;
      border-radius: 5px;
      @include media($tablet){
        margin-bottom: 10px;
      }
    }
  }
}
.banner-fa-left{
  margin-right: 5px;
}
