@import "vars";
@import "mixins";

.cool-card {
  aspect-ratio: 3/4;
  width: 350px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  color: $color-primary-white;
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
  background-color: #2b2d30;
  @include media($tablet){
    width: 25rem;
  }

  img {
    position: absolute;
    object-fit: contain;
    width: 90%;
    margin: auto;
    height: 100%;
    top: 0;
    left: 5%;
    opacity: 0.9;
    transition: filter .3s ease-out;
    aspect-ratio: 4/3;
    mix-blend-mode: normal;
  }

  h2 {
    position: absolute;
    inset: auto auto 10px 10px;
    margin: 0;
    transition: inset .3s .3s ease-out;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    outline: black;
    font-size: 2rem;
  }

  p, a {
    position: absolute;
    opacity: 0;
    max-width: 80%;
    transition: opacity .3s ease-out;
  }

  p {
    inset: auto auto 80px 30px;
  }

  a {
    inset: auto auto 40px 30px;
    color: inherit;
    text-decoration: none;
  }

  &:hover h2 {
    inset: auto auto 300px 10px;
    transition: inset .3s ease-out;
  }

  &:hover p, &:hover a {
    opacity: 1;
    transition: opacity .5s .1s ease-in;
  }

  &:hover img {
    transition: filter .3s ease-in;
    opacity: 1;
    filter: brightness(30%);
  }

}

.material-symbols-outlined {
  vertical-align: middle;
}
