@mixin title($_size){
  font-size: $_size;
}

@mixin respondTo($_minWidth, $_maxWidth){
  @media (min-width: $_minWidth) and (max-width: $_maxWidth) {
    @content;
  }

}

@mixin media($_size){
  @media (max-width: $_size) {
    @content
  }
}