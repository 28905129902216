@import "vars";
@import "mixins";

footer{
  margin-top: 50px;
  display: block;
  width: 100%;
  bottom: 0;

}
.footer-container{
  &__big {
    padding: 50px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(212,210,247, 0.5);
    background: linear-gradient(30deg, rgba(212,210,247,0.2) 0%, rgba(213,213,233,0.2) 51%, rgba(220,225,226,0.2) 100%);
  }
  &__title{
    display: inline-block;
    font-size: 1.5rem;
    font-weight: bold;
  }
  &__contacts{
    display: flex;
    flex-direction: row;
    @include media($mobile){
      flex-direction: column;
    }
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    &__item{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 20px 30px 0 10px;
      &__logo{
        font-size: 2rem;
        color: $blueColor;
      }
      & > p{
        font-size: .75rem;
        color: $experienceDescriptionColor
      }
      & > a{
        text-decoration: none;
        font-size: 1rem;
        color: black;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  &__small {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    font-size: .75rem;
    color: $experienceDescriptionColor;
    height: 50px;
  }
}