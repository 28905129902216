@import "vars";
@import "mixins";

.banner{
  @extend %flex-between;
  margin-bottom: 40px;
  @include media($mobile){
    flex-direction: column;
  }

  &__image-container{
    width:40%;
    border-radius: 5px;
    margin-right: 20px;
    @include  media($desktop){
      width: 50%;
      margin-right: 20px;
    }

    @include media($mobile){
      width: 100%;
    }
  }
  &__image{
    margin: auto;
    width: 100%;
    border-radius: 16px;
    transition: all .3s;
    &:hover{
      transition: all .3s;
      transform: scale(1.1);
    }
  }
  &__info{
    &-container{
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      @include media($mobile){
        width: 90%;
        margin: auto;
      }
      &>h1{
        font-size: 50px;
        margin-bottom: 10px;
      }
    }

    &-divider{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      @include media($mobile){
        margin-top: 20px;
      }
      &>img{
        width: 50px;
        height: 2px;
        margin-right: 10px;
        @include media($mobile){
          display: none;
        }
      }
      &>span{
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        color: $blueColor;
      }
    }
    &-description{
      &-text{
        color: $bannerTextColor;
        font-size: 20px;
      }
      &-buttons{
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        @include media($tablet){
          flex-direction: column;
        }
        @include media($mobile){
          flex-direction: row;
        }
      }
    }
  }
  &-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 10px 0 0;
    border-radius: 4px;
    height: 40px;
    font-size: 18px;
    padding: 0 20px;
    @include media($tablet){
      width: 80%;
    }
    @include media($mobile){
      width: 100%;
      height: 60px;
    }

  }
  &-fa{
    margin-left: 10px;
    font-weight: 300;
  }

}